import ApplicationController from "./application_controller";
import { useClickOutside } from "stimulus-use";

export default class extends ApplicationController {
  static targets = ["query", "dropdown", "searchIcon", "searchingIcon"];
  static values = {
    onSearch: String,
    onSelect: String,
  };

  connect() {
    super.connect();
    useClickOutside(this);
  }

  search(event) {
    event.preventDefault();
    this.searchIconTarget.hidden = true;
    this.searchingIconTarget.hidden = false;
    this.stimulate(this.onSearchValue, this.queryTarget.value);
  }

  select(event) {
    event.preventDefault();
    const id = event.currentTarget.getAttribute("data-value");
    this.dropdownTarget.hidden = true;
    this.queryTarget.value = "";
    this.stimulate(this.onSelectValue, id);
  }

  clickOutside() {
    // clears the value from the query box and closes the dropdown
    this.queryTarget.value = "";
    if (this.hasDropdownTarget) {
      this.dropdownTarget.hidden = true;
    }
  }
}
